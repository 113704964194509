<template>
  <div class="mian-container" ref="stContainer">
    <div v-if="defaultProps.isSearch" class="search-container">
      <slot name="tabs"></slot>
      <div class="search-container-cotent">
        <div class="search-container-cotent-items">
          <TextInput
            v-for="(config, index) in defaultProps.searchConfigs"
            :config="config"
            :optionConfig="defaultProps.optionConfig"
            :key="index"
            :form="queryParams"
            labelPosition="left"
            v-model="queryParams[config.modelKey]"
            @dataDeal="handleSearchDataDeal"
            @returnVal="getKeyToValue($event, config)"
          ></TextInput>
        </div>

        <div class="search-container-cotent-btn">
          <el-button @click="getData" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
        </div>
      </div>
    </div>
    <div class="table-container" :style="{ width: '100%' }">
      <!-- 顶部action -->
      <div class="action-container">
        <div class="action-container-left">
          <slot
            name="left-l"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
          <el-button
            v-if="defaultProps.isRefresh"
            @click="getData"
            class="refresh"
            icon="el-icon-refresh"
          ></el-button>
          <slot
            name="left-r"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
        </div>
        <div class="action-container-right">
          <slot
            name="right-l"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
          <!-- 模糊搜索 -->
          <el-input
            v-if="defaultProps.isFuzzyQuery"
            v-model="queryParams[defaultProps.fuzzyQueryKey]"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-popover
            placement="bottom-start"
            style="margin-left: 10px"
            v-if="defaultProps.isColumnSet"
          >
            <el-button slot="reference" class="action-btn">
              <img
                style="width: 24px; height: 20px"
                src="@/assets/images/safeDuty/column-control.png"
                alt=""
              />
            </el-button>
            <div>
              <DraggAble v-model="diyColumnProps">
                <transition-group class="draggable-column">
                  <div
                    v-for="(item, index) in diyColumnProps"
                    :key="index + 'sc'"
                    class="draggable-column-item"
                  >
                    <el-checkbox v-model="item.isShow" fill="#0080FF">
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </transition-group>
              </DraggAble>
              <div style="display: flex">
                <el-button
                  type="primary"
                  size="mini"
                  @click.native="handleSetColumn"
                  >确认</el-button
                >
                <el-button
                  @click.native="handleResetColumnSet"
                  type="danger"
                  size="mini"
                  >恢复默认</el-button
                >
              </div>
            </div>
          </el-popover>

          <el-button
            style="margin-left: 10px"
            @click="hiddenSearch"
            v-if="defaultProps.isHiddenSet"
            class="action-btn"
            ><img
              style="width: 24.91px; height: 13.86px"
              src="@/assets/images/safeDuty/hiden-item.png"
              alt=""
          /></el-button>
          <slot
            name="right-r"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
        </div>
      </div>
      <slot name="table-top"></slot>
      <div
        v-loading="tableLoading"
        :style="{ padding: defaultProps.innerPadding }"
      >
        <el-table
          ref="smTable"
          :data="defaultProps.isFillData ? tableData : localData"
          :row-class-name="tableRowClassName"
          :header-cell-class-name="headerRowClassName"
          :height="
            defaultProps.isSearch
              ? defaultProps.height
              : $refs.stContainer.offsetHeight
          "
          @selection-change="handleSelectionChange"
          :border="defaultProps.border"
          style="width: 100%"
        >
          <template slot="empty">
            <el-empty
              :description="defaultProps.empty.description"
              :image="defaultProps.empty.image"
            ></el-empty>
          </template>
          <el-table-column
            v-if="defaultProps.isSelection && localData?.length"
            type="selection"
            width="55"
            fixed="left"
          >
          </el-table-column>
          <template v-for="(column, index) in diyColumnProps">
            <template v-if="column.isShow">
              <el-table-column
                :label="column.label"
                :width="column.width || defaultProps.defaultColumnWidth"
                :prop="column.prop"
                :fixed="column.fixed"
                v-if="column.slot"
                :key="column.prop"
              >
                <template slot-scope="scope">
                  <slot
                    :name="column.prop"
                    :data="{ row: scope.row, index: scope.$index }"
                  >
                  </slot>
                </template>
              </el-table-column>
              <el-table-column
                :type="column.type"
                :label="column.label"
                :width="column.width || defaultProps.defaultColumnWidth"
                :prop="column.prop"
                :fixed="column.fixed"
                v-else-if="column.type"
                :key="column.prop + 'd1'"
              >
              </el-table-column>
              <el-table-column
                :type="column.type"
                :label="column.label"
                :width="column.width || defaultProps.defaultColumnWidth"
                :prop="column.prop"
                :fixed="column.fixed"
                :key="column.prop + 'd2'"
                v-else
              >
                <template slot-scope="scope">{{
                  scope.row[column.prop] ||
                  column.defaultValue ||
                  defaultProps.defaultValue
                }}</template>
              </el-table-column>
            </template>
          </template>
        </el-table>
      </div>

      <!-- 分页器 -->
      <div v-if="defaultProps.isPagination" class="pagination">
        <div>共计{{ queryParams.total }}条记录</div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryParams.currentSize"
          layout="sizes, prev, pager, next, jumper"
          :total="queryParams.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { updateTitleDiy } from "@/api/getData.js";
import { mapState, mapMutations } from "vuex";
import DraggAble from "vuedraggable";
import TextInput from "./TextInput.vue";
export default {
  name: "SimpleTable",
  components: { TextInput, DraggAble },
  props: {
    tableProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    queryFun: {
      type: Function,
      default: async () => {
        return [];
      },
    },
  },
  computed: {
    ...mapState(["diyRespList"]),
  },
  watch: {
    tableProps: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.defaultProps = _.assign(this.defaultProps, newValue);
      },
    },
  },
  created() {
    this.olddefaultProps = _.assign(this.defaultProps, this.tableProps);
    //this.init();
    this.defaultProps.immediateQuery && this.getData();
    this.handleColumnsSetting(true);
  },
  mounted() {
    // this.handleColumnsSetting(true);
  },
  data() {
    return {
      tableLoading: false,
      queryParams: {
        currentPage: 1,
        currentSize: 10,
        total: 0,
      },
      selectionRows: [],
      olddefaultProps: {},
      defaultProps: {
        tableName: "",
        empty: {
          description: "列表暂无数据",
          image: require("@/assets/images/RiskControl/risk-empty.png"),
        },
        immediateQuery: true,
        //columnProps里面有，用里面的
        defaultValue: "---",
        defaultColumnWidth: "",
        fuzzyQueryKey: "fuzzyQuery",
        currentPageKey: "currentPage",
        currentSizeKey: "currentSize",
        totalKey: "total",
        isFillData: false,
        isSelection: true,
        isColumnSet: true,
        isHiddenSet: true,
        isSearch: true,
        isPagination: true,
        isRefresh: true,
        isFuzzyQuery: true,
        border: false,
        innerPadding: 0,
        height: 380,
        // tabsConfigs: [
        //   // {label:'',value:''}
        // ],
        searchConfigs: [],
        columnProps: [],
        optionConfig: {
          value: "dictKey",
          label: "dictValue",
        },
      },
      diyColumnProps: [],
      localData: [],
    };
  },
  methods: {
    ...mapMutations(["SetDiyRespList"]),
    init() {
      //this.handleColumnsSetting(true);
    },
    handleSizeChange(i) {
      this.queryParams.currentSize = i;
      this.getData();
    },
    handleCurrentChange(i) {
      this.queryParams.currentPage = i;
      this.getData();
    },
    getData(isreload = false) {
      if (this.isFillData) return;
      this.tableLoading = true;
      if (isreload) {
        this.queryParams[this.defaultProps.currentPageKey] = 1;
        this.queryParams.currentPage = 1;
      } else {
        this.queryParams[this.defaultProps.currentPageKey] =
          this.queryParams.currentPage;
      }
      this.queryParams[this.defaultProps.currentSizeKey] =
        this.queryParams.currentSize;
      this.queryFun({ ...this.queryParams })
        .then((res) => {
          if (res) {
            this.localData = res.localData || [];
            this.queryParams.total = res.total || 0;
          } else {
            this.localData = [];
            this.queryParams.total = 0;
            this.queryParams.currentPage = 1;
          }
          this.$forceUpdate();
          this.$refs.smTable.doLayout();
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    getParams() {
      return this.queryParams;
    },
    setOption(data, key) {
      this.defaultProps.searchConfigs.find((item) => {
        if (item.modelKey === key) {
          item.option = data;
        }
      });
    },
    handleColumnsSetting(init = false) {
      // init false 更新
      // 没有tableName不记录
      // let tableName = this.defaultProps.tableName || this.$route.name;
      let tableName = this.defaultProps.tableName;
      if (!tableName) {
        this.diyColumnProps = this.olddefaultProps.columnProps;
        return;
      }
      let diyobj = this.diyRespList.obj[tableName];
      if (init) {
        if (diyobj) {
          this.diyColumnProps = diyobj.value;
        } else {
          this.diyColumnProps = this.defaultProps.columnProps;
        }
      } else {
        let divid = diyobj
          ? this.diyRespList.list[this.diyRespList.obj[tableName].index].id
          : "";
        updateTitleDiy({
          id: divid,
          tableKey: tableName,
          tableValue: JSON.stringify(this.diyColumnProps),
        }).then((res) => {
          //console.log(res);
        });
      }
    },
    hiddenSearch() {
      this.defaultProps.isSearch = !this.defaultProps.isSearch;
      //console.log(this.$refs.stContainer.offsetHeight);
    },
    handleResetColumnSet() {
      // this.defaultProps.columnProps.forEach((item) => {
      //   item.isShow = true;
      // });
      // this.diyColumnProps.forEach((item) => {
      //   item.isShow = true;
      // });
      // console.log(this.olddefaultProps);
      this.defaultProps = _.cloneDeepWith(this.olddefaultProps);
      this.diyColumnProps = this.defaultProps.columnProps;
    },
    handleSetColumn() {
      this.handleColumnsSetting();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "zdy-row";
      }
    },
    headerRowClassName() {
      return "zdy-header-row";
    },
    handleSelectionChange(rows) {
      this.selectionRows = rows;
      this.$emit("selection-change", rows);
    },
    handleSearchDataDeal(val) {
      this.queryParams = { ...this.queryParams, ...val };
    },
    reset() {
      let keys = Object.keys(this.queryParams);
      keys.forEach((key) => {
        if (this.queryParams[key] instanceof Array) {
          this.queryParams[key] = [];
        } else {
          this.queryParams[key] = "";
        }
      });
      this.queryParams = {
        ...this.queryParams,
        ...{
          currentPage: 1,
          currentSize: 10,
          total: 0,
        },
      };
      this.getData();
    },
    getKeyToValue(res, res2) {
      if (res.comType == "distpicker") {
        Object.keys(res.data).forEach((key) => {
          this.queryParams[key] = res.data[key];
        });
      } else if (res.comType == "select" && res.type) {
        // TODO 需要优化 动态key
        this.queryParams[res.type] = res.data.label || res.data.dictValue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  border-radius: 10px 10px 10px 10px;
}
::v-deep .zdy-row {
  background: #f5f9ff;
}
::v-deep .zdy-header-row {
  background-color: #f2f7fd !important;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.mian-container {
  display: flex;
  flex-direction: column;
}
.search-container {
  background: #ffffff;
  // padding: 16px 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  &-cotent {
    padding: 0 12px 16px 12px;
    display: flex;
    &-items {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, 360px);
      place-items: center;
      justify-items: end;
    }
    &-btn {
      display: flex;
      align-items: flex-end;
      align-self: end;
    }
  }
}
.table-container {
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  flex: 1;
  .action-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    &-left {
      display: flex;
    }
    &-right {
      display: flex;
      justify-content: space-between;
    }
    .action-btn {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #4278c9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .refresh {
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #4278c9;
      color: #4278c9;
      font-size: 20px;
      padding: 6px !important;
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
}
.draggable-column {
  display: flex;
  flex-direction: column;
  // &-item {
  // }
}
</style>
<!-- <style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
.zdy-header-row {
  /* background-color: #f2f7fd !important; */
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
</style> -->
