<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 16:33:54
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-30 11:43:00
 * @Description: 
-->
<template>
  <div class="main-box">
    <!-- 范围类型 -->
    <div class="main-box-top">
      <div
        :class="[
          'main-box-top-type',
          currentType == type.key ? 'main-box-top-type-active' : '',
        ]"
        :style="{ marginLeft: type.margin + 'px' }"
        @click="changeTimeRangeType(type.key)"
        v-for="(type, index) in rangeTypes"
      >
        {{ type.text }}
      </div>
    </div>
    <!-- 范围内小项 -->
    <div class="main-box-bottom">
      <!-- 切换范围 -->
      <div class="range" v-if="currentType == 'M'">
        <el-button
          style="border: unset"
          @click="nextTimeRange(-1)"
          class="el-icon-arrow-left"
        ></el-button>
        <div class="range-text">
          {{ showRangeText }}
        </div>
        <!-- <div></div> -->
        <el-button
          style="border: unset"
          @click="nextTimeRange(1)"
          class="el-icon-arrow-right"
        ></el-button>
      </div>
      <div class="range" v-if="currentType == 'Y'">
        <el-button
          style="border: unset"
          @click="nextTimeRange(-1)"
          class="el-icon-d-arrow-left"
        ></el-button>
        <div class="range-text">
          {{ showRangeText }}
        </div>
        <!-- <div></div> -->
        <el-button
          style="border: unset"
          @click="nextTimeRange(1)"
          class="el-icon-d-arrow-right"
        ></el-button>
      </div>
      <!-- 小项 -->
      <div class="main-box-bottom-times">
        <div
          @click="currentTime = time.value"
          :class="[
            'main-box-bottom-times-item',
            setTimeItemActive(time.value)
              ? 'main-box-bottom-times-item-active'
              : '',
          ]"
          v-for="(time, index) in timeItems"
        >
          {{ time.text }}
        </div>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="main-box-action">
      <el-button @click="reset"> 重置 </el-button>
      <el-button type="primary" @click="submit"> 确定 </el-button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "TimeRangeTable",
  model: {
    prop: "modeValue",
    event: "update:modelValue",
  },
  props: {
    modeValue: "",
  },
  data() {
    return {
      dayjs: dayjs,
      rangeTypes: {
        Y: { text: "按年选择", key: "Y", num: 9 },
        M: { text: "按月选择", key: "M", num: 12, margin: 8 },
        // D: { text: "按日选择", key: "D", margin: 8 },
      },
      currentType: "Y",
      currentTime: dayjs().format("YYYY-MM-DD"),
      timeItems: [],
    };
  },
  mounted() {
    this.setTimeItems();
  },
  computed: {
    showRangeText() {
      if (this.currentType == "Y") {
        return (
          dayjs(this.timeItems[0]?.value).format("YYYY") +
          "-" +
          dayjs(this.timeItems[this.timeItems.length - 1]?.value).format("YYYY")
        );
      } else if (this.currentType == "M") {
        return dayjs(this.currentTime).format("YYYY年");
      }
    },
  },
  methods: {
    setTimeItems(add = 1, time = "") {
      let items = [];
      let date = "";
      time ? (date = time) : (date = this.currentTime);
      if (this.currentType == "Y") {
        for (let i = 0; i < this.rangeTypes["Y"].num; i++) {
          items.push({
            text: dayjs(date)
              .add(i * add, "year")
              .format("YYYY"),
            value: dayjs(date).add(i * add, "year"),
          });
        }
      } else if (this.currentType == "M") {
        let year = dayjs(date).format("YYYY-01");
        for (let i = 0; i < this.rangeTypes["M"].num; i++) {
          items.push({
            text: dayjs(year).add(i, "month").format("MM月"),
            value: dayjs(year).add(i, "month"),
          });
        }
      }
      if (add < 0 && this.currentType == "Y") {
        this.timeItems = items.reverse();
      } else {
        this.timeItems = items;
      }
    },
    setTimeItemActive(time = "") {
      if (this.currentType == "Y") {
        return (
          dayjs(time).format("YYYY") == dayjs(this.currentTime).format("YYYY")
        );
      } else if (this.currentType == "M") {
        return (
          dayjs(time).format("MM月") == dayjs(this.currentTime).format("MM月")
        );
      }
    },
    changeTimeRangeType(type = "") {
      this.currentType = type;
      this.setTimeItems(1, this.timeItems[0].value);
    },
    nextTimeRange(add = 1) {
      if (this.currentType == "Y") {
        add > 0
          ? this.setTimeItems(
              add,
              this.timeItems[this.timeItems.length - 1].value
            )
          : this.setTimeItems(add, this.timeItems[0].value);
      } else if (this.currentType == "M") {
        this.currentTime = dayjs(this.currentTime).add(add, "year");
        this.setTimeItems(add);
      }
    },
    submit() {
      let showText = "";
      if (this.currentType == "Y") {
        showText = dayjs(this.currentTime).format("YYYY年");
      } else if (this.currentType == "M") {
        showText = dayjs(this.currentTime).format("YYYY年MM月");
      }
      this.$emit("submit", {
        currentTime: this.currentTime,
        currentType: this.currentType,
        showText,
      });
      // this.$emit("update:modelValue", this.currentTime);
    },
    //重置方法
    reset() {
      this.currentTime = dayjs().format("YYYY-MM-DD");
      this.setTimeItems();
      this.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  width: 367px;
  background-color: #ffffff;
  border-radius: 8px 8px 8px 8px;
  &-top {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    &-type {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      padding: 6px 16px;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #f5f5f5ff;
      cursor: pointer;
    }
    &-type-active {
      background: #ffffffff;
      color: #0080ff;
      border: 1px solid #0080ff;
    }
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 16px 0;
    &-times {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(auto, 104px));
      justify-content: space-around;
      gap: 6px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        //padding: 13px 36px;
        background: #f5f5f5;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #f5f5f5;
        cursor: pointer;
        width: 104px;
        height: 44px;
        // max-width: 104px;
        // max-height: 44px;
      }
      &-item-active {
        background: #0080ff;
        color: #ffffff;
        border: 1px solid #0080ff;
      }
    }
  }
  &-action {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
  }
}
.range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 54px;
  &-text {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    //margin: 0 60px;
  }
}
</style>
