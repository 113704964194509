/*
 * @Author: zhuchaowei
 * @Date: 2024-05-23 14:16:17
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-28 15:03:41
 * @Description:
 */
//状态字典
export const statusDict = [
  { dictValue: "启用", dictKey: 1, color: "#00BC0D" },
  { dictValue: "禁用", dictKey: 0, color: "#999999" },
];
//支付状态字典
export const payStatusDict = [
  {
    dictValue: "未付款",
    text: "未付款",
    value: 0,
    dictKey: 0,
    color: "#999999",
  },
  {
    dictValue: "已付款",
    text: "已付款",
    value: 1,
    dictKey: 1,
    color: "#00BC0D",
  },
];
//收款状态字典
export const collectStatusDict = [
  {
    dictValue: "未收款",
    text: "未收款",
    value: 0,
    dictKey: 0,
    color: "#999999",
  },
  {
    dictValue: "部分收款",
    text: "部分收款",
    value: 1,
    dictKey: 1,
    color: "#FDA835",
  },
  {
    dictValue: "已收款",
    text: "已收款",
    value: 2,
    dictKey: 2,
    color: "#00BC0D",
  },
];
//险种类型字典
export const insCategoryTypes = [
  {
    dictValue: "工程机械",
    text: "工程机械",
    value: 1,
    dictKey: 1,
    //color: "#999999",
  },
  {
    dictValue: "车险",
    text: "车险",
    value: 2,
    dictKey: 2,
    //color: "#999999",
  },
  {
    dictValue: "非车险",
    text: "非车险",
    value: 3,
    dictKey: 3,
    //color: "#999999",
  },
];
