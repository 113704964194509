<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyManagement`,
        }"
        >员工列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>员工详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <detailsa :userInfo="policyDetail" />
    </div>
  </div>
</template>

<script>
import detailsa from "@/views/routerPerformanceManagement/components/staffManagementList/components/details.vue";
import { getUserPerformance, policyProcessRevoke } from "@/api/policy";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { downloadZIP } from "@/utils/index";
export default {
  components: { detailsa },
  data() {
    return {
      policyDetail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.policyDetail = {
        userId: this.$route.query.id,
        year: this.$route.query.year,
        month: this.$route.query.month,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
